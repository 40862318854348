/***
Import fonts
***/
@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-Light.eot');
    src: url('font/OpenSans-Light.eot?#iefix') format('embedded-opentype'), url('font/OpenSans-Light.woff') format('woff'), url('font/OpenSans-Light.ttf') format('truetype'), url('font/OpenSans-Light.svg') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-Regular.eot');
    src: url('font/OpenSans-Regular.eot') format('embedded-opentype'), url('font/OpenSans-Regular.woff') format('woff'), url('font/OpenSans-Regular.ttf') format('truetype'), url('font/OpenSans-Regular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face { 
    font-family: 'Open Sans';
    src: url('font/OpenSans-Semibold.eot');
    src: url('font/OpenSans-Semibold.eot') format('embedded-opentype'), url('font/OpenSans-Semibold.woff') format('woff'), url('font/OpenSans-Semibold.ttf') format('truetype'), url('font/OpenSans-Semibold.svg') format('svg');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('font/OpenSans-Bold.eot');
    src: url('font/OpenSans-Bold.eot') format('embedded-opentype'), url('font/OpenSans-Bold.woff') format('woff'), url('font/OpenSans-Bold.ttf') format('truetype'), url('font/OpenSans-Bold.svg') format('svg');
    font-weight: 700;
    font-style: normal;
}

a:link, a:visited, a:hover, a:active, a:focus {
    outline: none;
}

* {
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box; /* Opera/IE 8+ */
}

a.select2-choice {
    color: #333;
}

a.select2-choice:hover {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
    font-family: 'Open Sans', Arial, Helvetica, sans-serif;
    line-height: none;
}

h1 {
    text-transform: uppercase;
    font-size: 30px;
}

.row-fluid [class*="span"],
.row [class*="span"] {
    padding: 20px;
    margin: 0;
}

#content .module .row-fluid [class*="span"],
#content .module .row [class*="span"] {
    padding: 0;
    margin-left: 2.12766%;
}

#content .module .row-fluid [class*="span"]:first-child,
#content .module .row [class*="span"]:first-child {
    margin-left: 0;
}

/* Info / Succès */
.ticket-info {
    background-color: #f8f5d2;
    border: 1px solid #d3d871;
    color: #635c56;
}

.ticket-success {
    background-color: #e5efcc;
    border: 1px solid #eaeed7;
    color: #635c56;
}

.ticket-error {
    background-color: #fed6d6;
    border: 1px solid #ff0000;
    color: #ff0000;
}

.ticket-success,
.ticket-info,
.ticket-error {
    padding: 7px;
    float: left;
    margin: 15px 0;
    font-size: 13px;
    border-radius: 5px !important;
}

    .ticket-success span,
    .ticket-info span,
    .ticket-error span {
        font-weight: bold;
    }

/* TABLE */
.table-holder {
    overflow: auto;
}

    .table-holder .table .align-left {
        text-align: left;
    }

    .table-holder .table .align-right {
        text-align: right;
    }

    .table-holder .table .align-center {
        text-align: center;
    }

    .table-holder table {
        width: 100%;
    }

        .table-holder table.table {
            margin-bottom: 0 !important;
        }

        .table-holder,
        .table-holder table td {
            color: #444;
            vertical-align: middle;
        }

            .table-holder table td,
            .table-holder table th {
                padding: 8px;
                empty-cells: show;
            }

            .table-holder table th {
                color: #4D4D4D;
                font-size: 13px;
                padding: 8px;
                vertical-align: middle;
            }

            .table-holder table thead tr:first-child {
                background: linear-gradient(to bottom, #FFFFFF 0%, #F1F1F1 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
            }

            .table-holder table th.sorting,
            .table-holder table th.sorting_asc,
            .table-holder table th.sorting_desc {
                padding: 8px 19px 8px 8px;
            }

            .table-holder .form-buttons {
                margin-top: 30px;
            }

            .table-holder table th .sort i {
                float: right;
                line-height: 20px;
            }

            .table-holder table th .sort:hover,
            .table-holder table th .sort:focus {
                text-decoration: none;
            }

            .table-holder table .th-checkbox {
                width: 0;
            }

            .table-holder table td label.checkbox {
                width: 100%;
            }

            .table-holder table td a {
                display: block;
                width: 100%;
            }

                .table-holder table td a:hover,
                .table-holder table td a:focus {
                    text-decoration: none;
                }

            .table-holder table.clickable td {
                cursor: pointer;
            }

            .table-holder table.clickable tr.active td,
            .table-holder table.clickable tr.active td:hover {
                background-color: #62737B !important;
                color: #FFF;
            }

            .table-holder .row-fluid [class*="span"] {
                min-height: 0;
            }

table.table {
    margin: 0;
}

.table-holder table td.details-holder,
.table-holder table th.details-holder {
    display: none;
}

.table-holder th.large {
    min-width: 250px;
    max-width: 250px;
}

.table-holder th.medium {
    min-width: 100px;
    max-width: 100px;
}

.table-holder th.small {
    min-width: 50px;
    max-width: 50px;
}

.table-holder .dataTable .details {
    padding: 0;
    background-color: #FFF;
}

.table-holder div.innerDetails {
    display: none;
    padding: 10px;
    background-color: #FFF;
}

.table-holder .dataTable .details tr td {
    background-color: #FFFFFF;
    border-left: 20px solid #F1F1F1;
    color: #777777;
    padding: 20px 20px 20px 25px;
}

.table-holder table thead input[type="text"] {
    display: block;
    min-width: 200px;
    width: 100%;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 28px;
    margin: 0;
}

.table-holder table thead input.search_init {
    min-width: 0;
}

.dataTables_filter {
    display: none;
}

.table-striped tbody > tr:nth-child(2n+1) > td, .table-striped tbody > tr:nth-child(2n+1) > th {
    background-color: #FCFCFC;
}

.table-hover tbody tr:hover > td, .table-hover tbody tr:hover > th {
    background-color: #F5F5F5;
}

.table-holder table thead td {
    background-color: #FFF;
}

.table-holder .show-details i {
    color: #AAA;
}

/* REMOVE ROUND */
.table-bordered,
.table-bordered thead th,
.table-bordered tbody td {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

/* FORM */
label.m-wrap, input.m-wrap, button.m-wrap, select.m-wrap, textarea.m-wrap {
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

input.m-wrap, button.m-wrap, select.m-wrap, textarea.m-wrap {
    font-family: "Segoe UI","Helvetica Neue",Helvetica,Arial,sans-serif;
}

label.m-wrap {
    display: block;
    margin-bottom: 5px;
}

.uneditable-input, textarea.m-wrap, input.m-wrap[type="text"], input.m-wrap[type="password"], input.m-wrap[type="datetime"], input.m-wrap[type="datetime-local"], input.m-wrap[type="date"], input.m-wrap[type="month"], input.m-wrap[type="time"], input.m-wrap[type="week"], input.m-wrap[type="number"], input.m-wrap[type="email"], input.m-wrap[type="url"], input.m-wrap[type="search"], input.m-wrap[type="tel"], input.m-wrap[type="color"] {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border-radius: 4px !important;
    -webkit-border-radius: 4px !important;
    box-shadow: none;
    color: #333333;
    filter: none;
    font-size: 14px;
    font-weight: normal;
    height: 20px;
    line-height: 20px;
    outline: 0 none;
    padding: 6px;
    vertical-align: top;
}

input.m-wrap {
    border: 1px solid #E5E5E5;
}

.toolbox-content input.m-wrap {
    border: 1px solid #3b4248;
    color: #a0a0a0;
}

textarea.m-wrap {
    height: auto;
}

    .uneditable-input.focus, textarea.m-wrap:focus, select.m-wrap:focus, input.m-wrap[type="text"]:focus, input.m-wrap[type="password"]:focus, input.m-wrap[type="datetime"]:focus, input.m-wrap[type="datetime-local"]:focus, input.m-wrap[type="date"]:focus, input.m-wrap[type="month"]:focus, input.m-wrap[type="time"]:focus, input.m-wrap[type="week"]:focus, input.m-wrap[type="number"]:focus, input.m-wrap[type="email"]:focus, input.m-wrap[type="url"]:focus, input.m-wrap[type="search"]:focus, input.m-wrap[type="tel"]:focus, input.m-wrap[type="color"]:focus, .m-uneditable-input:focus {
        border-color: #999999;
        box-shadow: none;
        outline: 0 none;
    }

input[disabled], select[disabled], textarea[disabled] {
    background-color: #F4F4F4;
    cursor: not-allowed;
}

input[readonly], select[readonly], textarea[readonly] {
    background-color: #F9F9F9;
    cursor: not-allowed;
}

input[type="radio"][disabled], input[type="checkbox"][disabled], input[type="radio"][readonly], input[type="checkbox"][readonly] {
    background-color: rgba(0, 0, 0, 0);
}

input.m-wrap[type="radio"], input.m-wrap[type="checkbox"] {
    -moz-box-sizing: border-box;
    cursor: pointer;
    line-height: normal;
    margin: 4px 0 0;
}

select.m-wrap, input.m-wrap[type="file"] {
    height: 34px;
    line-height: 30px;
}

select.m-wrap {
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #E5E5E5;
    filter: none;
    outline: medium none;
    width: 220px;
}

    select.m-wrap[multiple], select.m-wrap[size] {
        height: auto;
    }

    select.m-wrap:focus, input.m-wrap[type="file"]:focus, input.m-wrap[type="radio"]:focus, input.m-wrap[type="checkbox"]:focus {
        box-shadow: none;
        outline: thin dotted #333333;
        outline-offset: -2px;
    }

input.m-wrap[class*="span"] {
    float: none;
    height: 34px;
    margin-left: 0;
}

select.m-wrap[class*="span"], textarea.m-wrap[class*="span"] {
    float: none;
    margin-left: 0;
}

.input-prepend, .input-append {
    border: 0 none;
    display: inline-block;
}

    .input-append .add-on, .input-prepend .add-on {
        background-color: #E5E5E5;
        border: 1px solid #E5E5E5;
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        height: 24px;
        line-height: 24px;
        min-width: 16px;
        padding: 4px 5px;
        text-align: center;
        text-shadow: 0 1px 0 #FFFFFF;
        width: auto;
    }

        .input-append .add-on > .halflings-icon, .input-prepend .add-on > i, .input-prepend .add-on > [class^="fa-"] {
            margin-left: 3px;
            margin-top: 5px;
        }

            .input-append .add-on > .halflings-icon .input-append .add-on > i, .input-append .add-on > [class^="fa-"] {
                margin-left: 0;
                margin-top: 5px;
            }

    .input-append .m-wrap, .input-append .m-wrap:focus {
        margin-right: 1px;
    }

    .input-prepend input, .input-prepend input:focus {
        border-left: 0 none;
        margin-left: 1px;
    }

        .input-append input[class*="span"], .input-prepend input[class*="span"] {
            display: inline-block;
        }

.m-wrap.xsmall {
    width: 50px;
}

.m-wrap.small {
    width: 120px;
}

.m-wrap.medium {
    width: 206px;
}

.m-wrap.large {
    width: 320px;
}

.m-wrap.huge {
    font-size: 24px;
    height: 36px;
    line-height: 36px;
    padding: 22px 8px;
    width: 480px;
}

select.m-wrap.xsmall {
    width: 64px;
}

select.m-wrap.small {
    width: 134px;
}

select.m-wrap.medium {
    width: 220px;
}

select.m-wrap.large {
    width: 334px;
}

@media (max-width: 480px) {
    .m-wrap.small {
        width: 100px;
    }

    .m-wrap.medium {
        width: 150px;
    }

    .m-wrap.large {
        width: 200px;
    }

    .m-wrap.huge {
        font-size: 24px;
        height: 36px;
        line-height: 36px;
        padding: 22px 8px;
        width: 246px;
    }

    select.m-wrap.small {
        width: 114px;
    }

    select.m-wrap.medium {
        width: 164px;
    }

    select.m-wrap.large {
        width: 214px;
    }
}

#content .row-fluid article[class*="span"] {
    margin-left: 0;
}

#content .row-fluid > article.span1 {
    width: 8.333333333333332%;
}

#content .row-fluid > article.span2 {
    width: 16.666666666666664%;
}

#content .row-fluid > article.span3 {
    width: 25%;
}

#content .row-fluid > article.span4 {
    width: 33.33333333333333%;
}

#content .row-fluid > article.span5 {
    width: 41.66666666666667%;
}

#content .row-fluid > article.span6 {
    width: 50%;
}

#content .row-fluid > article.span7 {
    width: 58.333333333333336%;
}

#content .row-fluid > article.span8 {
    width: 66.66666666666666%;
}

#content .row-fluid > article.span9 {
    width: 75%;
}

#content .row-fluid > article.span10 {
    width: 83.33333333333334%;
}

#content .row-fluid > article.span11 {
    width: 91.66666666666666%;
}

#content .row-fluid > article.span12 {
    width: 100%;
}

/* LOGIN */
.login-form #avatar,
.login-form img,
.login-form .input-icon i.fa-user,
.login-form .input-icon i.fa-lock,
.forget-form .input-icon i.fa-envelope {
    display: none;
}

.login-form label,
.forget-form label {
    color: #333;
    font-family: 'Open Sans';
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: normal;
    line-height: 20px;
}

.login-form input.m-wrap[type="text"],
.login-form input.m-wrap[type="password"],
.forget-form input.m-wrap[type="text"] {
    font-size: 13px;
    height: 35px;
    line-height: 35px;
    padding: 0 5px;
    border: 1px solid #D0D0D0;
    border-radius: 0 !important;
    box-shadow: none;
    color: #555555;
    font-family: 'Open Sans';
    width: 100%;
}

.login-form .btn.btn-save:after,
.forget-form .btn.btn-delete:after,
.forget-form .btn.btn-save:after {
    clear: both;
}

.login-form,
.forget-form {
    background-color: transparent;
    border: medium none;
    margin: 0;
    padding: 0;
}

    .login-form .btn.btn-save,
    .forget-form .btn.btn-delete,
    .forget-form .btn.btn-save {
        background: none;
        background-color: #FF7F00;
        border: medium none;
        border-radius: 5px !important;
        color: #FFFFFF;
        cursor: pointer;
        font-family: 'Open Sans';
        margin-top: 15px;
        padding: 10px 30px;
        text-transform: uppercase;
        text-shadow: none;
        box-shadow: none;
        margin-left: 15px;
    }

        .login-form .btn.btn-save:hover,
        .forget-form .btn.btn-delete:hover,
        .forget-form .btn.btn-save:hover {
            background-color: #FFA119;
            color: #FFFFFF;
            text-decoration: none;
        }

    .login-form .form-actions,
    .forget-form .form-actions {
        padding: 0;
        margin: 0;
        border: none;
        background-color: transparent;
    }

.forget-form {
    display: none;
}

.login-form .checkbox {
    padding-left: 0;
}

.select2-container .select2-choice .lng_label {
    margin-right: 0;
    display: inline;
}
.extension_Hotel .optHeader.adultSup{
    display:none !important;
}
@media (max-width: 767px) {
    #content .row-fluid > article.span1 {
        width: 100%;
    }

    #content .row-fluid > article.span2 {
        width: 100%;
    }

    #content .row-fluid > article.span3 {
        width: 100%;
    }

    #content .row-fluid > article.span4 {
        width: 100%;
    }

    #content .row-fluid > article.span5 {
        width: 100%;
    }

    #content .row-fluid > article.span6 {
        width: 100%;
    }

    #content .row-fluid > article.span7 {
        width: 100%;
    }

    #content .row-fluid > article.span8 {
        width: 100%;
    }

    #content .row-fluid > article.span9 {
        width: 100%;
    }

    #content .row-fluid > article.span10 {
        width: 100%;
    }

    #content .row-fluid > article.span11 {
        width: 100%;
    }

    #content .row-fluid > article.span12 {
        width: 100%;
    }

    #content .module .row-fluid [class*="span"],
    #content .module .row [class*="span"] {
        margin-left: 0;
    }

    /*#content .select2-container .select2-choice div b:before {
        content: "";
    }*/
}

@media (max-width: 480px) {
    .select2-container .select2-choice .lng_label {
        display: none;
    }

    .select2-results .select2-result-label .lng_label {
        display: none;
    }
}

a.alpha8hover:hover {
    opacity: 0.8;
    filter: alpha(opacity=80);
}

.cycle-slideshow figure {
    margin: 0;
}

.cycle-slideshow img {
    width: 100%;
    height: auto;
}

.cycle-slideshow figcaption {
    display: none;
}

.owl-carousel a.slide {
    border: 0 solid #828F97;
    border-radius: 4px;
    color: #FFFFFF;
    display: block;
    font-size: 16px;
    margin-left: 1px;
    margin-right: 10px;
    min-height: 192px;
    padding: 15px;
    text-decoration: none;
    text-shadow: 1px 1px 1px #000000;
}

    .owl-carousel a.slide .title {
        font-family: 'Open Sans',Arial,Helvetica,sans-serif;
        font-size: 100%;
        font-weight: bold;
    }

    .owl-carousel a.slide .subtitle {
        font-family: 'Open Sans',Arial,Helvetica,sans-serif;
        font-size: 75%;
        font-weight: bold;
        padding-bottom: 8px;
    }

    .owl-carousel a.slide p {
        width: 160px;
    }

    .owl-carousel a.slide.bg1 {
        background: url('img/promo1.jpg') top right no-repeat;
        background-size: 100% auto;
    }

    .owl-carousel a.slide.bg2 {
        background: url('img/promo2fr.jpg') top right no-repeat;
        background-size: 100% auto;
    }

    .owl-carousel a.slide.bg3 {
        background: url('img/promo3.jpg') top right no-repeat;
        background-size: 100% auto;
        margin-right: 0;
    }

    .lang-en-us .owl-carousel a.slide.bg2 {
        background: url('img/promo2en.jpg') top right no-repeat;
        background-size: 100% auto;
    }

    .lang-it-it .owl-carousel a.slide.bg2 {
        background: url('img/promo2it.jpg') top right no-repeat;
        background-size: 100% auto;
    }

.bold {
    font-weight: bold;
}

.radio input[type="radio"], .checkbox input[type="checkbox"] {
    margin-left: 0;
}

@media (max-width:980px) {
    .owl-carousel a.slide {
        font-size: 11px;
    }

        .owl-carousel a.slide p {
            width: 120px;
            line-height: 110%;
        }

        .owl-carousel a.slide.bg3 {
            margin-right: 10px;
        }
}
.extension_Form p.required {
   font-size: 13px;
   margin-bottom: 5px;
}


/* patch bootstrap 4 -> bootstrap 3 */
html {
    font-size: initial;
}

body {
    line-height: 1.5;
}


/* Suppression de select 2 et Uniform */


/* Céer un compte */
select.date-day,
select.date-month,
select.date-year {
    display: inline-block;
    width: 8em;
    margin-bottom: .5em;
}
select.date-day {
    width: 4em;
}
.control-group input[type="checkbox"] {
    display: inline-block;
}
.extension_Form label.checkable-label {
    margin-bottom: 1rem;
}


#copyright{
    background: #000;
    text-align: center;
}
#copyright a{
    display: inline-block;
    padding: 0.6rem 0 0.5rem 0; 
    width: 100%;
    color: #fff;
    font-style: italic;
} 
#copyright svg{
    vertical-align: middle;
    margin-left: 0.2rem;
    margin-top: -2px;
}

@if ($copyright == false) {
     #copyright{
         display: none;
     }
}


/*=================================================================================*/
/*================                   $EVOLUTIONS                   ================*/
/* ========================================================================== */

// Dette technique globale obligatoire
@import "../../../../../app/deployment_sources/common/evolutions/patches-dette-technique-globale";

// Ajoute la compatibilité IOS6, IOS7, Safari Mac 5 à 8 et IE10
// Utiliser en complément l'option auto-prefix sur le compilateur lors du traitement du fichier global.scss (avec Koala ou Gulp)
@import "../../../../../app/deployment_sources/common/evolutions/old-browsers";

// Mise en forme du message d'alerte en cas de navigation sans JavaScript
@import "../../../../../app/deployment_sources/common/evolutions/no-script";

// RGPD / GDPR : si ajout des questions vie privée en fin d'inscription
@import "../../../../../app/deployment_sources/common/evolutions/rgpd";

// Nouveau calendrier Flex avec sélection du mois en cours 
// Garder commenter pour une nouvelle charte, décommenter pour patcher un vieux site
//@import "../../../../../app/deployment_sources/common/evolutions/calendar";

/*=================================================================================*/
/*================                 $EVOLUTIONS   FIN               ================*/
/*=================================================================================*/